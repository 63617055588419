.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner {
    width: 80vw;
    height: 40vh;
}

.banner img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}