.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(214, 214, 214);
    height: 60vh;
    width: 15rem;
    border-radius: 30px;
}

.imageContainer {
    width: 100%;
    height: 60%; /* Defina a altura fixa para o container da imagem */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Esconde qualquer parte da imagem que exceda os limites do container */
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta a imagem para cobrir totalmente o container */
}

.state {
    font-size: 10px;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details h4{
    margin: 2px;
}

.details p{
    margin: 2px;
}

.details {
    padding-bottom: 16px;
}

@media  screen and (max-width: 780px) {
    .card {
        width: 80vw;
    }
}