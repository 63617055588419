.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}

@media screen and (max-width: 780px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
}