.form {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background-color: rgb(205, 205, 211);
    padding: 2rem;
    margin: 2rem;
}

.form input {
    margin-bottom: 5px;
}

.form select {
    margin-top: 5px;
    margin-bottom: 5px;
}

#state label{
    margin-top: 20px;
}

.form button {
    margin-top: 10px;
}

@media screen and (max-width: 780px) {
    .form {
        width: 65vw;
        height: 50vh;
        justify-content: space-between;
    }
}